import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button, Image, Link, Slideshow } from 'components';
import { routes } from 'utils/routes';
import { resultsQuotesData } from 'utils/config/results';

class SectionResultsQuote extends Component {
  constructor(props) {
    super(props);
    this.state = { active: 0 };
    this.handleChange = this.handleChange.bind(this);
  };

  handleChange(newActive) {
    const maxIdx = resultsQuotesData.length - 1;
    let newIdx = newActive < 0 ? maxIdx : newActive;
    newIdx = newActive > maxIdx ? 0 : newIdx;
    this.setState({ active: newIdx });
  };

  render() {
    const { title, noButton, className } = this.props;
    const { active } = this.state;

    return (
      <div className={cx('section-results', [className])}>
        <h2 className='section-title'>{title}</h2>
        <div className='results-mobile-wrapper'>
          <Slideshow slidesPerPage={1} onChange={this.handleChange} value={active}>
            {resultsQuotesData.map(({ images, author, title, text }, i) => (
              <div key={i} className='results-wrapper'>
                <div className='section-media'>
                  <div className='media-wrapper'>
                    <Image url={images[0].url} alt={images[0].alt} />
                  </div>
                </div>
                <div className='section-text'>
                  <div className='quotes-wrapper'>
                    <blockquote>
                      <h4>{author}</h4>
                      <h3 className='mb-20'>{title}</h3>
                      <p dangerouslySetInnerHTML={{ __html: text }} />
                    </blockquote>
                  </div>
                </div>
              </div>
            ))}
          </Slideshow>
          <div className='quotes-nav'>
            <Button label='previous' onClick={() => this.handleChange(active-1)} className='button-quotes-prev' aria-label='Previous quote' />
            <Button label='next' onClick={() => this.handleChange(active+1)} className='button-quotes-next' aria-label='Next quote' />
          </div>
          {!noButton &&
            <Link to={routes.results.path} {...routes.results} className='button outline'>See more results</Link>
          }
        </div>

        <div className='results-wrapper hide-mobile'>
          <div className='section-media'>
            {resultsQuotesData.map(({ images }, i) => (
              <div key={i} className={cx('media-wrapper', { 'active': active === i })}>
                <Image url={images[0].url} alt={images[0].alt} />
                <Image url={images[1].url} alt={images[1].alt} />
              </div>
            ))}
          </div>
          <div className='section-text'>
            <div className='quotes-wrapper'>
              {resultsQuotesData.map(({ author, title, text }, i) => (
                <blockquote key={i} className={cx({ 'active': active === i })}>
                  <h4>{author}</h4>
                  <h3 className='mb-20'>{title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: text }} />
                </blockquote>
              ))}
            </div>
            <div className='quotes-nav'>
              <Button label='previous' onClick={() => this.handleChange(active-1)} className='button-quotes-prev' aria-label='Previous quote' />
              <Button label='next' onClick={() => this.handleChange(active+1)} className='button-quotes-next' aria-label='Next quote' />
            </div>
            {!noButton &&
              <Link to={routes.results.path} {...routes.results} className='button outline'>See more results</Link>
            }
          </div>
        </div>
      </div>
    );
  };
};

SectionResultsQuote.propTypes = {
  title: PropTypes.string.isRequired,
  noButton: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
};

SectionResultsQuote.defaultProps = {
  title: 'The results',
  noButton: false,
  className: '',
};

export default SectionResultsQuote;
