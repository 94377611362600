import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button, Link } from 'components';
import { routes } from 'utils/routes';

const Submenu = ({ className }) => {
  const [active, toggleSubmenu] = useState(false);
  const closeSubmenu = () => setTimeout(() => toggleSubmenu(false), 150);

  return (
    <div className={cx('nav-submenu', [className])}>
      <Button
        label='Our promise'
        onClick={() => toggleSubmenu(!active)}
        onBlur={closeSubmenu}
        className={cx('dropdown', { active })}
      />
      <div className='submenu-wrapper'>
        <Link to={routes.compare.path} {...routes.compare}>Why us</Link>
        <Link to={routes.guarantees.path} {...routes.guarantees}>Our guarantees</Link>
      </div>
    </div>
  );
};

Submenu.propTypes = {
  className: PropTypes.string.isRequired,
};

Submenu.defaultProps = {
  className: '',
};

export default Submenu;
