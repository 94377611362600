import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Image, Link, TrustpilotWidget } from 'components';

const Cover = ({ title, text, button, image, overlap, stamp, trustpilot, className }) => (
  <div className={cx('cover', { overlap }, [className])}>
    <Image url={image.url} alt={image.alt} className='section-media' />
    <div className='section-text'>
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      {text && <p className='mt-20' dangerouslySetInnerHTML={{ __html: text }} />}
      {(button || trustpilot) &&
        <div className='cover-results-actions'>
          {button && <Link to={button.path} external={button.external} newTab={button.newTab} className='button'>{button.label}</Link>}
          {trustpilot && <TrustpilotWidget />}
        </div>
      }
    </div>
    {stamp && <Image url='icons/icon_stamp.svg' alt='Straight teeth direct stamp of guarantee' className='stamp' />}
  </div>
);

Cover.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    external: PropTypes.bool.isRequired,
    newTab: PropTypes.bool.isRequired,
  }),
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  overlap: PropTypes.bool.isRequired,
  stamp: PropTypes.bool.isRequired,
  trustpilot: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
};

Cover.defaultProps = {
  overlap: false,
  stamp: false,
  trustpilot: false,
  className: '',
};

export default Cover;
