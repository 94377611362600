import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Image } from 'components';

const SectionNumbered = ({ title, list, image, iconNumber, reverse, className }) => (
  <div className={cx('section-numbered', { reverse }, [className])}>
    <div className='section-text'>
      <h2 dangerouslySetInnerHTML={{ __html: title }} />
      <ul>{list.map((node, idx) => <li key={idx}>{node}</li>)}</ul>
    </div>
    <div className='section-media'>
      <Image url={image.url} alt={image.alt} />
      <Image url={iconNumber.url} alt={iconNumber.alt} className='icon-number' />
    </div>
  </div>
);

SectionNumbered.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.node).isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  iconNumber: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  reverse: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
};

SectionNumbered.defaultProps = {
  reverse: false,
  className: '',
};

export default SectionNumbered;
