import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button, Slideshow } from 'components';
import { ourStoryData } from 'utils/config/about';

class SectionOurStory extends Component {
  constructor(props) {
    super(props);
    this.state = { active: 0 };
    this.handleChange = this.handleChange.bind(this);
  };

  handleChange(newActive) {
    const max = ourStoryData[ourStoryData.length-1].id;
    let newId = newActive < 0 ? max : newActive;
    newId = newActive > max ? 0 : newId;
    this.setState({ active: newId });
  };

  render() {
    const { title, className } = this.props;
    const { active } = this.state;

    return (
      <div className={cx('section-ourstory', [className])}>
        <h2 className='section-title mb-50' dangerouslySetInnerHTML={{ __html: title }} />
        <div className='ourstory-wrapper'>
          <div className='story-years'>
            {ourStoryData.map(({ id, year }) => (
              <div key={id} className={cx('year-wrapper', { 'active': active === id })}>
                {year}
              </div>
            ))}
          </div>
          <div className='story-nav'>
            <Button label='previous' onClick={() => this.handleChange(active-1)} className='button-story-prev' aria-label='Previous story' />
            <div className='caroussel-wrapper'>
              <Slideshow slidesPerPage={1} onChange={this.handleChange} value={active}>
                {ourStoryData.map(({ id, title, text }) => (
                  <div key={id} className={cx('story-wrapper', { 'active': active === id })}>
                    <h4 className='mb-20'>{title}</h4>
                    <p>{text}</p>
                  </div>
                ))}
              </Slideshow>
            </div>
            <Button label='next' onClick={() => this.handleChange(active+1)} className='button-story-next' aria-label='Next story' />
          </div>
        </div>
      </div>
    );
  };
};

SectionOurStory.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

SectionOurStory.defaultProps = {
  title: 'Our story',
  className: '',
};

export default SectionOurStory;
