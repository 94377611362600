import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import cx from 'classnames';

const Image = ({ url, isExternalUrl, alt, caption, hideCaption, calcWidth, className, ...props }) => (
  <figure className={cx({ 'hide-figcaption': hideCaption }, [className])} {...props}>
    {isExternalUrl ? <img src={url} alt={alt} /> : loadImage(url, alt, calcWidth)}
    {!!caption && caption.trim().length > 0 && !hideCaption &&
      <figcaption>{caption}</figcaption>
    }
  </figure>
);

const loadImage = (filename, alt, calcWidth) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              publicURL
              childImageSharp {
                sizes(quality: 80, maxWidth: 800) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => n.node.relativePath.includes(filename));
      if (!image) { return null; }

      if (image.node.childImageSharp) {
        const sizes = image.node.childImageSharp.sizes;
        const w = calcWidth ? `${Math.floor(sizes.aspectRatio*100)}%` : '100%';
        return (
          <Img
            alt={alt}
            sizes={sizes}
            placeholderStyle={{ visibility: 'hidden' }}
            className='image-wrapper'
            style={{ width: w }}
          />
        );
      }

      return (
        <img
          alt={alt}
          src={image.node.publicURL}
          // placeholderStyle={{ visibility: 'hidden' }}
        />
      );
    }}
  />
);

Image.propTypes = {
  url: PropTypes.string.isRequired,
  isExternalUrl: PropTypes.bool.isRequired,
  alt: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  hideCaption: PropTypes.bool.isRequired,
  calcWidth: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
};

Image.defaultProps = {
  url: '',
  isExternalUrl: false,
  caption: '',
  hideCaption: false,
  calcWidth: false,
  className: '',
};

export default Image;

