import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Image } from 'components';

const SectionCompare = ({ title, text, image, icon, reverse, className }) => (
  <div className={cx('compare-wrapper', { reverse }, [className])}>
    <div className='section-text'>
      <h3 dangerouslySetInnerHTML={{ __html: title }} />
      <p>{text}</p>
    </div>
    <div className='section-media'>
      <Image url={image.url} alt={image.alt} />
      <Image url={icon.url} alt={icon.alt} className='icon-wrapper' />
    </div>
  </div>
);

SectionCompare.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  icon: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  reverse: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
};

SectionCompare.defaultProps = {
  reverse: false,
  className: '',
};

export default SectionCompare;
