import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Image, Link, Slideshow } from 'components';
import { routes } from 'utils/routes';
import { stepsData } from 'utils/config/steps';

class SectionSteps extends Component {
  constructor(props) {
    super(props);
    this.state = { active: 0, next: 1, group: 0 };
    this.handleChange = this.handleChange.bind(this);
  };

  handleChange(newActive) {
    const max = stepsData[stepsData.length-1].id;
    let newId = newActive < 0 ? max : newActive;
    newId = newActive > max ? 0 : newId;
    const nextId = newId+1 > max ? 0 : newId+1;
    this.setState({ active: newId, next: nextId, group: stepsData[newId].group });
  };

  render() {
    const { className } = this.props;
    const { active, next, group } = this.state;

    return (
      <div className={cx('section-steps fvw', [className])}>
        <h2 className='section-title mb-20'>A simpler, cheaper and easier way to get Your Smile Delivered&trade;.</h2>
        <p className='section-title-text'>Friendly and experienced dentists and tracked treatment, at the touch of a button.</p>
        <div className='content-wrapper mt-50'>
          <div className='caroussel-mobile-wrapper'>
            <Slideshow slidesPerPage={1} onChange={this.handleChange} value={active} className=''>
              {stepsData.map(({ id, image, title, text }) => (
                <div key={id} className='caroussel-wrapper'>
                  <div className='section-media'>
                    <Image url={image.url} alt={image.alt} calcWidth />
                  </div>
                  <div className='section-text'>
                    <h2>{title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: text }} />
                  </div>
                </div>
              ))}
            </Slideshow>
            <button className='button arrow-prev' onClick={() => this.handleChange(active-1)} aria-label='Previous step'>
              <div className='button-arrow'/>
            </button>
            <button className='button arrow-next' onClick={() => this.handleChange(active+1)} aria-label='Next step'>
              <div className='button-arrow'/>
            </button>
          </div>

          <div className='caroussel-wrapper hide-mobile'>
            <div className='section-media'>
              <Image url={stepsData[active].image.url} alt={stepsData[active].image.alt} calcWidth />
            </div>
            <div className='section-text'>
              <p className='small'>{stepsData[active].step}</p>
              <h2>{stepsData[active].title}</h2>
              <p dangerouslySetInnerHTML={{ __html: stepsData[active].text }} />
              <button className='button arrow-next' onClick={() => this.handleChange(active+1)} aria-label='Next step'>
                {stepsData[next].step}<div className='button-arrow'/>
              </button>
            </div>
          </div>
          <div className='steps-nav-wrapper'>
            <div className={cx('steps-left', { 'active': group < 2 })}>
              <p className='title'>
                <span className='hide-mobile'>Typical straightening steps</span>
                <span className='hide-desktop upper'>Typical steps</span>
              </p>
              <div className='steps-nav'>
                {stepsData.filter(stepFiltered => stepFiltered.group === 0)
                  .map(({ id, label }) => (
                    <div
                      key={id}
                      className={cx('step-wrapper', { 'active': id === active })}
                      data-step={id+1}
                    >
                      <span>{label}</span>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className={cx('steps-right', { 'active': group === 1 })}>
              <p className='title'>
                <span className='hide-mobile'>Premium added steps - Beyond basics</span>
                <span className='hide-desktop upper'>Beyond basics</span>
              </p>
              <div className='steps-nav'>
                {stepsData.filter(stepFiltered => stepFiltered.group === 1)
                  .map(({ id, label }) => (
                    <div
                      key={id}
                      className={cx('step-wrapper', { 'active': id === active })}
                      data-step={id+1}
                    >
                      <span>{label}</span>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <div className='flex-center'>
          <Link to={routes.howItWorks.path} {...routes.howItWorks} className='button outline'>See how it works</Link>
        </div>
      </div>
    );
  };
};

SectionSteps.propTypes = {
  className: PropTypes.string.isRequired,
};

SectionSteps.defaultProps = {
  className: '',
};

export default SectionSteps;
