import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

const Slideshow = ({ children, slidesPerPage, className, ...props }) => {
  const [width, setWidth] = useState(0);
  const settings = {
    centered: true,
    infinite: true,
    slidesPerPage: slidesPerPage,
    className: className,
  };

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    width < 1000
      ? <Carousel {...settings} {...props}>{children}</Carousel>
      : <Fragment>{children}</Fragment>
  );
};

Slideshow.propTypes = {
  children: PropTypes.node.isRequired,
  slidesPerPage: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

Slideshow.defaultProps = {
  slidesPerPage: 2,
  className: 'fvw',
};

export default Slideshow;
