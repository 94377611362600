import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { injectIntl } from 'react-intl';

import { Image } from 'components';
import locales from 'utils/locales';

const LanguageSwitcher = ({ intl: { locale } }) => {
  const [active, toggleSubmenu] = useState(false);
  const regex = /\/[a-zA-Z]{0,2}-?[a-zA-Z]{0,3}\//;
  const path = typeof window !== 'undefined' ? window.location.pathname.replace(regex, '/') : '/#';

  return (
    <div className='nav-submenu nav-language'>
      <button onClick={() => toggleSubmenu(!active)} className={cx('dropdown', { active })}>
        <Image url={`flags/flag_${locale}.png`} alt='United Kingdom' />
      </button>

      <div className='submenu-wrapper'>
        {Object.keys(locales).map(lang => {
          const url = locales[lang].default ? path : `/${locales[lang].path}${path}`;
          return (
            <button key={lang} onClick={() => window.location.href = url }>
              <Image url={`flags/flag_${locales[lang].path}.png`} alt={locales[lang].locale} />
              {locales[lang].locale}
            </button>
          )
        })}
      </div>
    </div>
  );
};

LanguageSwitcher.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string
  }).isRequired,
};

export default injectIntl(LanguageSwitcher);
