import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactPlayer from 'react-player';
import Modal from 'react-modal';

import { Image, Link } from 'components';

const Lightbox = ({ image, videoUrl, isVideo, caption, hasCaption, action, hasAction, className }) => {
  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  useEffect(() => {
    /* prevent body scroll when lightbox is open */
    if (showDialog) {
      document.documentElement.classList.add('html-no-scroll');
    }

    return () => {
      document.documentElement.classList.remove('html-no-scroll');
    };
  });

  return (
    <div className={cx('lightbox-wrapper', [className])}>
      <div className={cx('lightbox-image', { 'video-wrapper': isVideo })}>
        <button onClick={open}>
          <Image url={image.url} alt={image.alt} data-name={(hasCaption && caption.title) || ''} />
        </button>
      </div>
      <Modal isOpen={showDialog} onRequestClose={close} ariaHideApp={false} className='modal-wrapper'>
        <div className={cx({ 'modal-content': hasCaption })}>
        {isVideo
          ? <div className='player-wrapper modal-media'>
              <ReactPlayer url={videoUrl} className='react-player' height='100%' width='100%' playing controls />
            </div>
          : <Image url={image.url} alt={image.alt} className='modal-media' />
        }
        {hasCaption &&
          <div className='modal-caption'>
            {caption.title && <h3 className='mb-20'>{caption.title}</h3>}
            <p>{caption.text}</p>
            {hasAction &&
              <Link to={action.path} external={action.external} newTab={action.newTab} className='button outline'>{action.label}</Link>
            }
          </div>
        }
        </div>
        <button onClick={close} tabIndex='0' className='button-close'>Close</button>
      </Modal>
    </div>
  );
};

Lightbox.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  videoUrl: PropTypes.string.isRequired,
  isVideo: PropTypes.bool.isRequired,
  caption: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string.isRequired,
  }),
  action: PropTypes.shape({
    path: PropTypes.string.isRequired,
    external: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
  }),
  hasCaption: PropTypes.bool.isRequired,
  hasAction: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
};

Lightbox.defaultProps = {
  videoUrl: '',
  isVideo: false,
  hasCaption: false,
  hasAction: false,
  className: '',
};

export default Lightbox;
