import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import cx from 'classnames';

import { Link } from 'components';
import { routes } from 'utils/routes';

const TrustpilotWidget = ({ showReviews, className }) => {
  const ref = useRef(null);
  const config = {
    className: 'trustpilot-widget',
    'data-locale': 'en-GB',
    'data-template-id': `${showReviews ? '539ad60defb9600b94d7df2c' : '5419b732fbfb950b10de65e5'}`,
    'data-businessunit-id': '5a1304900000ff0005b1003d',
    'data-style-height': `${showReviews ? '500px' : '50px'}`,
    'data-style-width': '100%',
    'data-theme': 'light',
    'data-stars': `${showReviews ? '1,2,3,4,5' : ''}`,
  };

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  });

  return (
    <div className={cx('trustpilot-wrapper', [className])}>
      <Helmet>
        <script src='https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js' async></script>
      </Helmet>
      <div ref={ref} {...config}>
        <Link to={routes.trustpilot.path} {...routes.trustpilot}>Trustpilot</Link>
      </div>
    </div>
  );
};

TrustpilotWidget.propTypes = {
  showReviews: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
};

TrustpilotWidget.defaultProps = {
  showReviews: false,
  className: '',
};

export default TrustpilotWidget;
