import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Lightbox, Slideshow, TrustpilotWidget } from 'components';
import { routes } from 'utils/routes';


const GridResults = ({ link, trustpilot, slide, hasAction, className, pictures }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = (e) => {
    if (!link.to) {
      e.preventDefault();
      setOpen(!open);
    }
  };
  const visiblePictures = (link.to || open) ? pictures : pictures.slice(0, 8);

  return (
    <div className={className}>
      {trustpilot &&
       <div className='section-results-actions'>
         <a href={link.to || routes.results.path} className='button outline'>{link.label}</a>
         <TrustpilotWidget />
       </div>
      }
      <div className='section-results-grid'>
        {slide &&
          <Slideshow slidesPerPage={1.4} className='carousel-results fvw'>
            {visiblePictures.map((p, i) =>
              <Lightbox
                key={i}
                image={{ url: p.url, alt: p.alt }}
                hasCaption
                caption={{ title: p.name, text: p.quote }}
                hasAction={hasAction}
                action={{ ...routes.results, label: 'View more results' }}
              />
            )}
          </Slideshow>
        }
        {!slide && visiblePictures.map((p, i) =>
          <Lightbox
            key={i}
            image={{ url: p.url, alt: p.alt }}
            hasCaption
            caption={{ title: p.name, text: p.quote }}
            hasAction={hasAction}
            action={{ ...routes.results, label: 'View more results' }}
          />
        )}
      </div>
      {!trustpilot &&
       <div className='flex-center mt-50 mb-90'>
         <a href={(link && link.to) || '#'} onClick={toggleOpen} className='button outline'>{link.label}</a>
       </div>
      }
    </div>
  );
};

GridResults.propTypes = {
  link: PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
  }),
  trustpilot: PropTypes.bool.isRequired,
  slide: PropTypes.bool.isRequired,
  hasAction: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  pictures: PropTypes.array,
};

GridResults.defaultProps = {
  trustpilot: false,
  slide: false,
  hasAction: false,
  className: '',
  pictures: [],
};

export default GridResults;
