import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as T } from 'react-intl';
import cx from 'classnames';
import ReactPlayer from 'react-player';

import { Image, Lightbox, Link } from 'components';

const SectionTextMedia = ({
  title,
  subtitle,
  hint,
  price,
  text,
  button,
  otherButton,
  image,
  stamp,
  reverse,
  lightbox,
  isVideo,
  videoUrl,
  className,
}) => (
  <div className={cx('section-wrapper', { reverse }, [className])}>
    <div className='section-text'>
      <h2 dangerouslySetInnerHTML={{ __html: title }} />
      {hint && <p className='mt-10'>{hint}</p>}
      {price && <p className='price'><T id={price}>{txt => txt}</T></p>}
      {subtitle && <p className='large mt-10'><b>{subtitle}</b></p>}
      <p className='mt-20' dangerouslySetInnerHTML={{ __html: text }} />
      {button &&
        <div className='section-actions'>
          <Link to={button.path} external={button.external} newTab={button.newTab} className={`button ${button.level}`}>
            {button.label}
          </Link>
          {otherButton &&
            <Link
              to={otherButton.path}
              external={otherButton.external}
              newTab={otherButton.newTab}
              className={`button ${otherButton.level}`}
            >
              {otherButton.label}
            </Link>
          }
        </div>
      }
    </div>
    <div className='section-media'>
      {lightbox && <Lightbox image={image} videoUrl={videoUrl} isVideo={isVideo} />}
      {(!lightbox && !isVideo) && <Image url={image.url} alt={image.alt} />}
      {(!lightbox && isVideo) &&
        <div className='player-wrapper no-pointer'>
          <ReactPlayer url={videoUrl} className='react-player' height='100%' width='100%' playing muted loop />
        </div>
      }
      {stamp && <Image url='icons/icon_stamp.svg' alt='Straight teeth direct stamp of guarantee' className='stamp' />}
    </div>
  </div>
);

SectionTextMedia.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  hint: PropTypes.string,
  price: PropTypes.node,
  text: PropTypes.string.isRequired,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    level: PropTypes.oneOf(['', 'outline', 'ghost']),
    external: PropTypes.bool.isRequired,
    newTab: PropTypes.bool.isRequired,
  }),
  otherButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    level: PropTypes.oneOf(['', 'outline', 'ghost']),
    external: PropTypes.bool.isRequired,
    newTab: PropTypes.bool.isRequired,
  }),
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    noBlur: PropTypes.bool,
  }).isRequired,
  stamp: PropTypes.bool.isRequired,
  reverse: PropTypes.bool.isRequired,
  lightbox: PropTypes.bool.isRequired,
  isVideo: PropTypes.bool.isRequired,
  videoUrl: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

SectionTextMedia.defaultProps = {
  stamp: false,
  reverse: false,
  lightbox: false,
  isVideo: false,
  videoUrl: '',
  className: '',
};

export default SectionTextMedia;
