import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

const Input = ({ id, name, label, placeholder, value, disabled, type, required, className }) => (
  <div className={cx('input-wrapper', [className])}>
    { type === 'textarea'
      ? <textarea id={id} name={name} placeholder={placeholder} defaultValue={value} disabled={disabled} required={required} />
      : <input id={id} name={name} type={type} placeholder={placeholder} defaultValue={value} disabled={disabled} required={required} />
    }
    <label htmlFor={id}>{label}</label>
  </div>
);

Input.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
};

Input.defaultProps = {
  label: '',
  placeholder: '',
  value: '',
  disabled: false,
  type: 'text',
  required: false,
  className: '',
};

export default Input;
