import React, { Fragment, useState } from 'react';
import cx from 'classnames';

import { Button, FAQs } from 'components';
import { generalFaqsData, costFaqsData, treatmentFaqsData, accountFaqsData } from 'utils/config/faqs';

const localConfig = [
  {
    id: 0,
    button: { label: "General FAQ's" },
    faqs: { data: generalFaqsData },
  },
  {
    id: 1,
    button: { label: 'Cost & Payments' },
    faqs: { data: costFaqsData, title: 'Cost & Payments' },
  },
  {
    id: 2,
    button: { label: 'During treatment' },
    faqs: { data: treatmentFaqsData, title: 'During treatment' },
  },
  {
    id: 3,
    button: { label: 'My account issues' },
    faqs: { data: accountFaqsData, title: 'My account issues' },
  },
];


const SectionFAQs = () => {
  const [active, toggleFaq] = useState(0);

  return (
    <Fragment>
      <div className='section-topics'>
        <p className='t-center mb-40'><b>How can we help?</b></p>
        <div className='topics-wrapper'>
          {localConfig.map(({ id, button: { label} }) => (
            <Button key={id} label={label} level='outline' onClick={() => toggleFaq(id)} />
          ))}
        </div>
      </div>

      {localConfig.map(({ id, faqs: { data, title } }) => (
        <FAQs key={id} faqsData={data} title={title} className={cx({ 'hide': active !== id })} />
      ))}
    </Fragment>
  );
};

export default SectionFAQs;
