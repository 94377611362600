export const resultsQuotesData = [
  {
    images: [
      { url: 'pages/reviews/results/home_results_onea.png', alt: "Marta's straight teeth journey" },
      { url: 'pages/reviews/results/home_results_oneb.png', alt: "Marta's smile transformation" },
    ],
    author: 'Marta',
    title: "Life changing!",
    text: `I am getting a lot of compliments about my teeth now, the part of me I was always trying to hide.
           The process through all the stages was really smooth it was very easy.
           The customer service is absolutely perfect.<br/><br/>
           It was the best decision I could make and it only took me 10 months to fix something I struggled with all my life and thought was too late for me to take care of.
           Even my dentist couldn't believe the results and how amazing the change was in such a short time.`,
  },
  {
    images: [
      { url: 'pages/reviews/results/joe_full_face.jpg', alt: "Joe's straight teeth journey" },
      { url: 'pages/reviews/results/joe_zoom.jpg', alt: "Joe's smile transformation" },
    ],
    author: 'Joe',
    title: "I’m more proud than ever of my smile!",
    text: `I think their service and product are great, and clearly the aligners work, so I’ve been recommending them to anyone who’s asked about my treatment.`,
  },
  {
    images: [
      { url: 'pages/reviews/results/tiffany_full_face_2.jpg', alt: "Tiffany's straight teeth journey" },
      { url: 'pages/reviews/results/tiffany_zoom.jpg', alt: "Tiffany's smile transformation" },
    ],
    author: 'Tiffany',
    title: "It's the best thing I've ever done!",
    text: `I honestly smile more and I am happier. I've never been able to say this about any company before... I have NO cons.<br><br>
           I can honestly say I've never experienced such good customer service,
           there is always someone there to help and there is never a question too big or too small,
           the team genuinely cares about your teeth journey and are there as little or as much as you need them!`,
  },
  {
    images: [
      { url: 'pages/reviews/results/tony_full_face.jpg', alt: "Tony's straight teeth journey" },
      { url: 'pages/reviews/results/tony_zoom.jpg', alt: "Tony's smile transformation" },
    ],
    author: 'Tony',
    title: "I would highly recommend this to absolutely anyone",
    text: `I have friends who have had Invisalign and companies similar and have never had a good experience,
           they were overcharged for the most ridiculous things such as containers for your aligners,
           and their experience going to their dentist every two weeks is so time consuming if you’re an adult you don’t have time to go to a dentist every two weeks.<br><br>
           You can tell by the team at Straight Teeth Direct that they have spent years creating this company to make sure everything has been perfected,
           and from my experience they are leading dentistry industry by storm.`,
  },
  {
    images: [
      { url: 'pages/reviews/results/shivali_full.jpg', alt: "Shivali's straight teeth journey" },
      { url: 'pages/reviews/results/shivali_zoom.jpg', alt: "Shivali's smile transformation" },
    ],
    author: 'Shivali',
    title: "I couldn't be happier with the results!",
    text: `I’ve also been surprised at how many compliments I’ve received on my teeth, even from people who didn’t know I was using aligners! It really is amazing how much a straight smile can boost your confidence.`,
  },
  {
    images: [
      { url: 'pages/reviews/results/toni_full_face.jpg', alt: "Toni's straight teeth journey" },
      { url: 'pages/reviews/results/toni_zoom_2.jpg', alt: "Toni's smile transformation" },
    ],
    author: 'Toni',
    title: "I genuinely cannot give enough praise!",
    text: `I have SO much more confidence and EVERYONE has noticed my teeth's improvement.
           You cannot even tell when I have the aligners in - no one ever notices until I tell them about it.<br><br>
           As the Mumma of two beautiful babies who take up a LOT of my time when I’m not working,
           these aligners delivered direct to my front door are perfect for me and the process is SO EASY!`,
  }
];
