export const ourStoryData = [
  {
    id: 0,
    year: '2009 - 2016',
    title: 'The foundation',
    text: 'We were looking to create the type of dental practice experience we wanted ourselves. High tech clinic with TVs in the ceiling offering state of the art smile enhancement using the latest cosmetic orthodontic technologies.'
  },
  {
    id: 1,
    year: '2014 - 2016',
    title: 'Going Digital',
    text: 'To help more people access their dream smile we created an online assessment system, and also helped more clinics do the same using the latest digital education, booking and automation systems to grow their orthodontic practices.'
  },
  {
    id: 2,
    year: '2015 -',
    title: 'Going Direct to Consumer',
    text: 'We saw the possibility to integrate our online assessment systems with state of the art 3D printing technologies to create a comprehensive teledentistry platform enabling at home cosmetic teeth straightening for a 1/3 of the cost of in clinic solutions. We launched in April 2016 and got enquiries from around the world.'
  },
  {
    id: 3,
    year: '2017 -',
    title: 'Fully Mobile and Global Solution',
    text: 'Straight Teeth Direct™ went live and global with iOS and Android apps, starting the journey to data driven smiles delivered in 50+ countries. This is just the beginning.'
  },
];
