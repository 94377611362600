import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const GoBack = ({ label, className }) => {
  const handleOnClick = () => {
    window.history.go(-1);
    return false;
  };

  return (
    <button className={cx('button arrow-prev', [className])} onClick={handleOnClick} aria-label='Go back'>
      <div className='button-arrow'/>{label}
    </button>
  );
};

GoBack.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

GoBack.defaultProps = {
  label: 'Back',
  className: '',
};

export default GoBack;
