import React, { useState } from 'react';
import Sticky from 'react-stickynode';

import { Link } from 'components';
import { routes } from 'utils/routes';
import Burgermenu from './Burgermenu';
import LanguageSwitcher from './LanguageSwitcher';
import MobileMenu from './MobileMenu';

const Header = () => {
  const [isOpen, toggleMenu] = useState(false);
  const openMenu = () => toggleMenu(true);
  const closeMenu = () => toggleMenu(false);

  return (
    <header>
      <div className='header-shadow'>
        <div className='header-wrapper'>
          <div className='nav-wrapper fw'>
            <Link to={routes.home.path} {...routes.home} className='nav-logo' aria-label='Straight Teeth Direct logo'>
              <div className='brand-logo' />
            </Link>
            <Burgermenu onClick={openMenu} />
          </div>

          <div className='nav-wrapper'>
            <LanguageSwitcher />
          </div>
        </div>
      </div>

      <Sticky activeClass='active' className='nav-sticky'>
        <div className='header-shadow z1'>
          <div className='subheader-wrapper'>
            <div className='nav-menu'>
              <Link activeClassName='active' to={routes.home.path} {...routes.home}>Home</Link>
              <Link activeClassName='active' to={routes.compare.path} {...routes.compare}>Compare</Link>
              <Link activeClassName='active' to={routes.pricing.path} {...routes.pricing}>Pricing</Link>
              <Link activeClassName='active' to={routes.guarantees.path} {...routes.guarantees}>Guarantees</Link>
            </div>
            <Link to={routes.getStarted.path} {...routes.getStarted} className='button ml-a'>Free e-consultation</Link>
          </div>
        </div>
      </Sticky>

      <Sticky activeClass='active' className='nav-sticky-mobile'>
        <div className='header-shadow'>
          <div className='header-wrapper'>
            <div className='nav-wrapper fw'>
              <Link to={routes.getStarted.path} {...routes.getStarted} className='button default'>Free e-consultation</Link>
              <Burgermenu onClick={openMenu} />
            </div>

            <div className='nav-wrapper'>
              <LanguageSwitcher />
            </div>
          </div>
        </div>
      </Sticky>

      <MobileMenu isOpen={isOpen} closeMenu={closeMenu} />
    </header>
  );
};

export default Header;
