import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const FAQs = ({ title, faqsData, className }) => {
  const [open, toggleFaq] = useState(null);
  const handleToggle = (openId) => toggleFaq(open === openId ? null : openId);

  return (
    <div className={className}>
      <h3 className='section-title mb-50'>{title}</h3>

      <div className='section-faq'>
        {faqsData.map(({ id, title, text }) => (
          <div key={id} className={cx('faq-wrapper', { 'active': open === id })}>
            <button
              className='button-faq'
              aria-controls={`faq${id}`}
              aria-expanded={open === id ? 'true' : 'false'}
              onClick={() => handleToggle(id)}
            >
              {title}
            </button>
            <div id={`faq${id}`} role='region' className='text-faq' tabIndex='-1'>{text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

FAQs.propTypes = {
  title: PropTypes.string.isRequired,
  faqsData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.node.isRequired,
    text: PropTypes.node.isRequired,
  })).isRequired,
  className: PropTypes.string.isRequired,
};

FAQs.defaultProps = {
  title: 'General FAQs',
  className: '',
};

export default FAQs;
