import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { IntlProvider, addLocaleData } from 'react-intl';

import cx from 'classnames';

import Header from './Header';
import Footer from './Footer';
import 'assets/stylesheets/application.scss';

// handle i18n
import { flattenMessages } from 'utils/flattenMessages';
import enData from 'react-intl/locale-data/en';
import ukData from 'react-intl/locale-data/uk';

import en from 'locales/en.json';
import uk from 'locales/uk.json';

const messages = { en, uk };

addLocaleData([...enData, ...ukData]);

const Layout = ({ children, locale, className }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <IntlProvider key={locale} locale={locale} messages={flattenMessages(messages[locale])}>
        <Fragment>
          <Header siteTitle={data.site.siteMetadata.title} />
          <div className={cx('layout-wrapper', [className])}>
            <main>{children}</main>
          </div>
          <Footer />
        </Fragment>
      </IntlProvider>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

Layout.defaultProps = {
  className: '',
};

export default Layout;
