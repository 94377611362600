import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Slideshow, Image } from 'components';

const IconsAlignerService = ({ className }) => (
  <div className={cx('section-icons-aligner', [className])}>
    <h3 className='section-title mb-50'>
      The only aligner service to guarantee results, with no hidden costs.
    </h3>

    <div className='section-icons'>
      <Slideshow>
        <div className='icon-wrapper'>
          <Image url='icons/icon_shipping.svg' alt='Free global shipping' />
          <p><b>Free worldwide shipping</b></p>
        </div>
        <div className='icon-wrapper'>
          <Image url='icons/icon_guarantees.svg' alt='Industry leading guarantees' />
          <p><b>Industry leading guarantees</b></p>
        </div>
        <div className='icon-wrapper'>
          <Image url='icons/icon_led.svg' alt='Orthodontist led treatment' />
          <p><b>Orthodontist<br/>Led</b></p>
        </div>
        <div className='icon-wrapper'>
          <Image url='icons/icon_clock.svg' alt='Full customer support' />
          <p><b>Round the clock customer support</b></p>
        </div>
      </Slideshow>
    </div>
  </div>
);

IconsAlignerService.propTypes = {
  className: PropTypes.string.isRequired,
};

IconsAlignerService.defaultProps = {
  className: '',
};

export default IconsAlignerService;
