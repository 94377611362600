import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Image, Link } from 'components';
import { routes } from 'utils/routes';

const localConfig = [
  [
    { icon: 'logo.png', label: 'Home', ...routes.home },
    { icon: 'icons/menu/icon_how_it_works.png', label: 'How it works', ...routes.howItWorks },
    { icon: 'icons/menu/icon_pricing.png', label: 'Pricing', ...routes.pricing },
    { icon: 'icons/menu/icon_results.png', label: 'Results', ...routes.results },
    { icon: 'icons/menu/icon_promise.png', label: 'Guarantees', ...routes.guarantees },
  ],
  [
    { icon: 'icons/menu/icon_faqs.png', label: 'FAQs', ...routes.faq },
    { icon: 'icons/menu/icon_compare.png', label: 'Why us', ...routes.compare },
    { icon: 'icons/menu/icon_shipping.png', label: 'Shipping info', ...routes.shipping },
    { icon: 'icons/menu/icon_blog.png', label: 'Blog', ...routes.blog },
    { icon: 'icons/menu/icon_about.png', label: 'About', ...routes.about },
  ],
  [
    { icon: 'icons/menu/icon_contact.png', label: 'Contact us', ...routes.contacts },
    { icon: '#', label: 'Terms of use', ...routes.terms },
    { icon: '#', label: 'Privacy', ...routes.privacy },
    { icon: '#', label: 'Returns & Refunds', ...routes.refunds },
  ],
  [
    { icon: 'icons/menu/icon_join_team.png', label: 'Join the team', ...routes.joinTeam },
    { icon: 'icons/menu/icon_dentists.png', label: 'For dentists', ...routes.dentists },
  ],
];

const MobileMenu = ({ isOpen, closeMenu }) => (
  <div className={cx('menu-mobile', { 'active': isOpen })}>
    <div className='menu-header'>
      <button className='close-menu' onClick={closeMenu}>
        <Image url='icons/menu/icon_less.png' alt='Close menu' />
      </button>
      <div className='menu-header-actions'>
        <Link to={routes.login.path} {...routes.login} className='button outline nav-login'>Log in</Link>
        <Link to={routes.getStarted.path} {...routes.getStarted} className='button ml-10'>Get started</Link>
      </div>
    </div>

    <div className='menu-content'>
      {localConfig.map((group, idx) => (
        <div key={idx} className='menu-links'>
        {group.map(({ icon, label, path, external, newTab }, idx) => (
          <Link key={idx} to={path} external={external} newTab={newTab} className='link-wrapper'>
            <Image url={icon} alt='Decorative icon' className='mr-10' />
            <span>{label}</span>
          </Link>
        ))}
        </div>
      ))}

      <div className='menu-footer'>
        <Link to={routes.facebook.path} {...routes.facebook} className='ml-20 mr-20'>
          <Image url='icons/menu/icon_facebook.png' alt='Facebook logo' />
        </Link>
        <Link to={routes.youtube.path} {...routes.youtube}>
          <Image url='icons/menu/icon_youtube.png' alt='Youtube logo' />
        </Link>
        <Link to={routes.instagram.path} {...routes.instagram}>
          <Image url='icons/menu/icon_instagram.png' alt='Instagram logo' />
        </Link>
      </div>
    </div>
  </div>
);

MobileMenu.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

MobileMenu.defaultProps = {
  isOpen: false,
};

export default MobileMenu;
