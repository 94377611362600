import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Button = ({ label, onClick, level, disabled, className, ...props }) => (
  <button
    className={cx('button', [level], [className])}
    disabled={disabled}
    onClick={disabled ? () => {} : onClick}
    {...props}
  >
    {label}
  </button>
);

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  level: PropTypes.oneOf(['', 'outline', 'ghost']).isRequired,
  disabled: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
};

Button.defaultProps = {
  label: 'Click me',
  onClick: () => {},
  level: '',
  disabled: false,
  className: '',
};

export default Button;
