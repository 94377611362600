import React, { Fragment } from 'react';
import { FormattedMessage as T } from 'react-intl';

export const stepsData = [
  {
    id: 0,
    image: { url: 'pages/steps_one.png', alt: 'Your free e-consultation and teeth straightening app' },
    group: 0,
    step: 'Step One',
    title: <Fragment>Free e-consultation</Fragment>,
    text: 'Submit photos of your teeth and answer some questions that will be reviewed by a dentist who will determine if you are suitable.',
    label: <Fragment>E-Consultation<br/><T id="index.steps.econsultation">{txt => txt}</T></Fragment>,
  },
  {
    id: 1,
    image: { url: 'pages/steps_two.png', alt: 'Your free e-consultation and teeth straightening app' },
    group: 0,
    step: 'Step Two',
    title: <Fragment><T id="index.steps.smilebox">{txt => txt}</T> SmileBox&trade;</Fragment>,
    text: 'Take and return impressions of your teeth to see the predicted results first. Receive your Straight Teeth Plan & 3D smile simulation.',
    label: <Fragment>SmileBox&trade;<br/><T id="index.steps.smilebox">{txt => txt}</T></Fragment>,
  },
  {
    id: 2,
    image: { url: 'pages/steps_three.png', alt: 'Your free e-consultation and teeth straightening app' },
    group: 0,
    step: 'Step Three',
    title: <Fragment><T id="index.steps.straightbox">{txt => txt}</T> StraightBox&trade;</Fragment>,
    text: 'Receive your initial aligners and starter kit that will help you achieve your smile goals.',
    label: <Fragment>Straight Teeth kit<br/><T id="index.steps.straightbox">{txt => txt}</T></Fragment>,
  },
  {
    id: 3,
    image: { url: 'pages/steps_four.png', alt: 'Your free e-consultation and teeth straightening app' },
    group: 1,
    step: 'Step Four',
    title: <Fragment>Free App Movement Tracker</Fragment>,
    text: 'Through the aligner intelligent monitoring that combines unique tech with real dentist reviews, get updates on your treatment progress and % tooth movement monthly.',
    label: <Fragment>App Movement Tracker<br/><T id="index.steps.tracker">{txt => txt}</T></Fragment>,
  },
  {
    id: 4,
    image: { url: 'pages/steps_five.png', alt: 'Your free e-consultation and teeth straightening app' },
    group: 1,
    step: 'Step Five',
    title: <Fragment>Free staged aligner deliveries</Fragment>,
    text: 'Receive your aligners in stages according to your progress so adjustments can be made and we can guarantee your final results.',
    label: <Fragment>Staged aligner deliveries<br/><T id="index.steps.aligner">{txt => txt}</T></Fragment>,
  },
  {
    id: 5,
    image: { url: 'pages/steps_six.png', alt: 'Your free e-consultation and teeth straightening app' },
    group: 1,
    step: 'Step Six',
    title: <Fragment>Free Refinements & Retainers</Fragment>,
    text: 'Receive as many aligners as needed to achieve the most optimal clinical outcome and a complementary set of retainers to maintain your new smile.',
    label: <Fragment>Refinements & Retainers<br/><T id="index.steps.refinements">{txt => txt}</T></Fragment>,
  },
];
