export const routes = {
  'home': { path: '/', external: false, newTab: false },
  'about': { path: '/about/', external: false, newTab: false },
  'compare': { path: '/teeth-straightening-comparator/', external: false, newTab: false },
  'contacts': { path: '/contact/', external: false, newTab: false },
  'faq': { path: '/faqs/', external: false, newTab: false },
  'guarantees': { path: '/guarantee/', external: false, newTab: false },
  'howItWorks': { path: '/how-it-works/', external: false, newTab: false },
  'maintainerPlan': { path: '/maintainer-plan/', external: false, newTab: false },
  'pricing': { path: '/pricing/', external: false, newTab: false },
  'results': { path: '/reviews/', external: false, newTab: false },
  'shipping': { path: '/shipping/', external: false, newTab: false },
  'teethWhitening': { path: '/teeth-whitening-upgrade/', external: false, newTab: false },
  'apart': { path: '/what-sets-us-apart/', external: false, newTab: false },
  'partners': { path: '/#', external: false, newTab: false },
  'press': { path: '/#', external: false, newTab: false },
  'cookies': { path: '/terms-of-use/', external: false, newTab: false },
  'privacy': { path: '/privacy/', external: false, newTab: false },
  'terms': { path: '/terms-of-use/', external: false, newTab: false },
  'refunds': { path: '/returns-and-refunds/', external: false, newTab: false },

  'login': { path: 'https://app.straightteethdirect.com/login', external: true, newTab: true },
  'getStarted': { path: 'https://app.straightteethdirect.com', external: true, newTab: true },
  'blog': { path: '/blog/', external: true, newTab: true },
  'joinTeam': { path: 'https://straight-teeth-direct.workable.com/', external: true, newTab: true },
  'dentists': { path: 'https://www.straightteethdirect.com/dentists/', external: true, newTab: false },
  'trustpilot': { path: 'https://uk.trustpilot.com/review/straightteethdirect.com', external: true, newTab: true },

  'appStore': { path: 'https://goo.gl/W194rR', external: true, newTab: true },
  'playStore': { path: 'https://goo.gl/wT8Eqp', external: true, newTab: true },
  'facebook': { path: 'https://www.facebook.com/straightteethdirect/?fref=ts', external: true, newTab: true },
  'youtube': { path: 'https://www.youtube.com/channel/UC1ZZZQYW576aEp82kLGZEhA', external: true, newTab: true },
  'instagram': { path: 'https://www.instagram.com/straightteethdirect/', external: true, newTab: true },

  'homeVideo': { path: 'https://www.youtube.com/watch?v=YTlrdoJU9jw', external: true, newTab: true },
  'dailymirror': { path: 'https://www.mirror.co.uk/tech/new-app-promises-straighten-your-13472019', external: true, newTab: true },
  'ft': { path: 'https://www.ft.com/content/e90df75c-14c4-11e9-a168-d45595ad076d?sharetype=blocked', external: true, newTab: true },
  'marieclaire': { path: '/#', external: true, newTab: true },
  'thesun': { path: 'https://www.thesun.co.uk/news/8114658/posh-spice-syndrome-miserable-brits-bad-teeth-smile/', external: true, newTab: true },
  'sundaytimes': { path: 'https://www.thetimes.co.uk/article/the-best-startups-we-saw-this-year-by-branson-and-co-0jr998r9s', external: true, newTab: true },
  'independent': { path: 'https://www.independent.co.uk/life-style/teeth-unhappy-stress-dentist-work-personal-life-finance-relationships-a8709411.html', external: true, newTab: true },
  'mhealth': { path: 'https://thejournalofmhealth.com/disruptive-technology-in-dentistry-rethinking-the-model/', external: true, newTab: true },
  'inc': { path: 'https://www.inc.com/talib-visram/bracing-for-brexit-in-the-face-of-uncertainty.html', external: true, newTab: true },
  'stylist': { path: 'https://www.stylist.co.uk/beauty/oral-care-treatments-products/262954', external: true, newTab: true },
};
