import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const WistiaVideo = (props) => {
  useEffect(() => {
    window._wq = window._wq || [];
    window._wq.push({ id: props.wistiaHash, onReady: (video) => {
      video.bind('popovershow', () => document.documentElement.classList.add('html-no-scroll'));
      video.bind('popoverhide', () => document.documentElement.classList.remove('html-no-scroll'));
      video.bind('beforeremove', () => video.unbind);
    }});
  });

  return (
    <div className='wistia-video'>
      <Helmet>
        <script src={`https://fast.wistia.com/embed/medias/${props.wistiaHash}.jsonp`} async></script>
      </Helmet>
      <div className={`wistia_embed wistia_async_${props.wistiaHash} popover=true popoverContent=html playerColor=00ffe3`}>
        <img className='wistia-thumbnail' src={props.url} alt={props.alt} />
      </div>
    </div>
  );
};

WistiaVideo.propTypes = {
  wistiaHash: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
}

export default WistiaVideo;
