import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Image, Link, Slideshow } from 'components';
import { pressData } from 'utils/config/press';

const SectionPress = ({ className }) => {
  const [open, toggleOpen] = useState(false);
  const [active, handleChange] = useState(0);
  const visiblePress = open ? pressData : pressData.slice(0, 3);

  return (
    <div className={className}>
      <div className='section-press'>
        <Slideshow slidesPerPage={1} onChange={handleChange} value={active}>
          {visiblePress.map(({ title, image, link }, idx) =>
            <div key={idx} className='press-wrapper'>
              <div className='press-logo'>
                <Image url={image.url} alt={image.alt} className={image.style} />
              </div>
              <h3>{title}</h3>
              <Link to={link.path} {...link} className='button outline mt-a'>Read</Link>
            </div>
          )}
        </Slideshow>
        <div className='press-nav'>
          <Button label='previous' onClick={() => handleChange(active-1)} className='button-press-prev' aria-label='Previous article' />
          <Button label='next' onClick={() => handleChange(active+1)} className='button-press-next' aria-label='Next article' />
        </div>
      </div>

      {!open &&
        <div className='flex-center mt-50'>
          <Button label='See more press' level='outline' onClick={toggleOpen} />
        </div>
      }
    </div>
  );
};

SectionPress.propTypes = {
  className: PropTypes.string.isRequired,
};

SectionPress.defaultProps = {
  className: '',
};

export default SectionPress;
