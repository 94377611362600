import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import locales from 'utils/locales';

function SEO({ description, lang, meta, image, keywords, title, location }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            keywords
          }
        }
      }
    `
  );

  const canonicalUrl = `${site.siteMetadata.siteUrl}${location.pathname}`;
  const ukUrl = `${site.siteMetadata.siteUrl}/uk${location.pathname}`;
  const metaDescription = description || site.siteMetadata.description;
  const imageUrl = site.siteMetadata.siteUrl + image;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title || site.siteMetadata.title}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:url',
          content: canonicalUrl,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'google-site-verification',
          content: 'O4W94koIQ1RPYEEgZnKm7sYSVGqyPbItpE3LCV4ak64',
        },
        {
          name: 'google-site-verification',
          content: 'wEf5BULX9FhZLs7PspYsQVEjRUKh0Mm1Kv8-Auo_OQc',
        },
        {
          name: 'twitter:site',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: (keywords || site.siteMetadata.keywords).join(','),
        },
      ].concat(
        imageUrl
          ? [
            {
              property: 'og:image',
              content: imageUrl,
            },
            {
              name: 'twitter:image',
              content: imageUrl,
            },
            {
              name: 'twitter:card',
              content: 'summary_large_image',
            },
          ]
          : [
            {
              name: 'twitter:card',
              content: 'summary',
            },
          ]
      ).concat(meta)}
    >
      <link rel="canonical" href={canonicalUrl} />
      <link rel="alternate" hreflang="en-us" href={canonicalUrl} />
      <link rel="alternate" hreflang="en-gb" href={ukUrl} />
      <script type="text/javascript">{`
        (function(o){var b="https://rapidpanda.io/anywhere/",t="c612e40bd89f4452a778df19ad4065396516a64d4494487e948dba25c1119b87",a=window.AutopilotAnywhere={_runQueue:[],run:function(){this._runQueue.push(arguments);}},c=encodeURIComponent,s="SCRIPT",d=document,l=d.getElementsByTagName(s)[0],p="t="+c(d.title||"")+"&u="+c(d.location.href||"")+"&r="+c(d.referrer||""),j="text/javascript",z,y;if(!window.Autopilot) window.Autopilot=a;if(o.app) p="devmode=true&"+p;z=function(src,asy){var e=d.createElement(s);e.src=src;e.type=j;e.async=asy;l.parentNode.insertBefore(e,l);};y=function(){z(b+t+'?'+p,true);};if(window.attachEvent){window.attachEvent("onload",y);}else{window.addEventListener("load",y,false);}})({});
      `}</script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: Object.keys(locales).find(locale => locales[locale].default),
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
};

export default SEO;
