module.exports = {
  uk: {
    path: 'uk',
    locale: 'United Kingdom',
  },
  en: {
    path: 'en',
    locale: 'Global',
    default: true,
  },
}
