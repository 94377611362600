import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components';
import { routes } from 'utils/routes';
import Submenu from './Submenu';

const Burgermenu = ({ onClick }) => (
  <Fragment>
    <button className='burgermenu' onClick={onClick}>
      <div className='label'>
        <span>Menu</span>
        <div className='dots'/>
      </div>
    </button>
    <div className='nav-menu fw'>
      <div className='menu-wrapper'>
        <Link to={routes.howItWorks.path} {...routes.howItWorks}>How it works</Link>
        <Link to={routes.results.path} {...routes.results}>Results</Link>
        <Submenu />
      </div>
      <div className='ml-a'>
        <Link to={routes.getStarted.path} {...routes.getStarted} className='button default'>Free e-consultation</Link>
        <Link to={routes.login.path} {...routes.login} className='button outline nav-login'>Log in</Link>
      </div>
    </div>
  </Fragment>
);

Burgermenu.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Burgermenu;
