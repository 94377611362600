import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import { injectIntl } from 'react-intl';

import locales from 'utils/locales';

const Link = ({ to, intl: { locale }, external, newTab, ...props }) => {
  const path = locales[locale].default ? to : `/${locale}${to}`;
  const target = newTab ? '_blank' : '_self';

  return external
    ? <a href={to} target={target} rel='noopener noreferrer' {...props}>{props.children}</a>
    : <GatsbyLink {...props} to={path} />;
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    locale: PropTypes.string
  }).isRequired,
  external: PropTypes.bool.isRequired,
  newTab: PropTypes.bool.isRequired,
};

Link.defaultProps = {
  external: false,
  newTab: false,
};

export default injectIntl(Link);
