import { routes } from 'utils/routes';

export const footerLinks = [
  {
    title: 'Learn',
    links: [
      { label: 'How it works', ...routes.howItWorks },
      { label: 'Pricing', ...routes.pricing },
      { label: 'Compare options', ...routes.compare },
      { label: 'Blog', ...routes.blog },
      { label: 'Join the team', ...routes.joinTeam },
    ],
  },
  {
    title: 'Support',
    links: [
      { label: 'FAQ', ...routes.faq },
      { label: 'Shipping Info', ...routes.shipping },
      { label: 'Contact us', ...routes.contacts },
      { label: 'Get started', ...routes.getStarted },
      { label: 'User login', ...routes.login },
    ],
  },
  {
    title: 'Trust',
    links: [
      { label: 'Results', ...routes.results },
      { label: 'Guarantees', ...routes.guarantees },
      { label: 'About', ...routes.about },
      { label: 'Press', ...routes.press },
      { label: 'Partners', ...routes.partners },
    ],
  },
];
