import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { TrustpilotWidget, WistiaVideo } from 'components';
import { routes } from 'utils/routes';


const GridResultsVideo = ({ link, trustpilot, className, videos }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = (e) => {
    if (!link.to) {
      e.preventDefault();
      setOpen(!open);
    }
  };
  const visibleVideos = (link.to || open) ? videos : videos.slice(0, 8);

  return (
    <div className={className}>
      <Helmet>
        {/* This is needed to load Wistia videos... Please see the the WistiaVideo component */}
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
      </Helmet>

      {trustpilot &&
        <div className='section-results-actions'>
          <a href={link.to || routes.results.path} className='button outline'>{link.label}</a>
          <TrustpilotWidget />
        </div>
      }
      <div className='section-results-grid'>
        {visibleVideos.map((c, i) => <WistiaVideo key={i} {...c} />)}
      </div>
      {!trustpilot &&
       <div className='flex-center mt-50 mb-90'>
         <a href={(link && link.to) || '#'} onClick={toggleOpen} className='button outline'>{link.label}</a>
       </div>
      }
    </div>
  );
};

GridResultsVideo.propTypes = {
  link: PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
  }),
  trustpilot: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  videos: PropTypes.array
};

GridResultsVideo.defaultProps = {
  trustpilot: false,
  className: '',
  videos: []
};

export default GridResultsVideo;
