import { routes } from 'utils/routes';

export const pressData = [
  {
    id: 0,
    title: "New APP promises to straighten your teeth from home - and could be ideal if you're scared of the dentist",
    image: { url: 'logos/logo_dailymirror.png', alt: 'Daily Mirror logo', style: 'maxw-12' },
    link: { ...routes.dailymirror },
  },
  {
    id: 1,
    title: 'Half of British people unhappy with their teeth, study claims',
    image: { url: 'logos/logo_independent.png', alt: 'The independent logo', style: '' },
    link: { ...routes.independent },
  },
  {
    id: 2,
    title: 'CHEER UP ‘Posh Spice Syndrome’ affects ‘miserable’ Brits with bad teeth who rarely smile',
    image: { url: 'logos/logo_thesun.png', alt: 'The Sun logo', style: 'maxw-12' },
    link: { ...routes.thesun },
  },
  {
    id: 3,
    title: 'Disruption is thriving in unexpected areas',
    image: { url: 'logos/logo_ft.png', alt: 'Financial Times logo', style: 'maxw-5' },
    link: { ...routes.ft },
  },
  {
    id: 4,
    title: 'The best start‑ups we saw this year — by Branson and Co',
    image: { url: 'logos/logo_sundaytimes.png', alt: 'The Sunday Times logo', style: '' },
    link: { ...routes.sundaytimes },
  },
  {
    id: 5,
    title: 'Disruptive Technology in Dentistry – Rethinking the Model',
    image: { url: 'logos/logo_mhealth.png', alt: 'The journal of mHealth logo', style: 'maxw-18' },
    link: { ...routes.mhealth },
  },
  {
    id: 6,
    title: 'With a Looming Deadline and Plenty of Uncertainty, U.K. Entrepreneurs Grapple With How to Handle Brexit',
    image: { url: 'logos/logo_inc.png', alt: 'Inc logo', style: 'maxw-10' },
    link: { ...routes.inc },
  },
  {
    id: 7,
    title: 'How far would you go for the perfect smile?',
    image: { url: 'logos/logo_stylist.png', alt: 'Stylist magazine logo', style: 'maxw-15' },
    link: { ...routes.stylist },
  },
];
