import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button } from 'components';
import { costsData, guaranteeData, dentistsData, labData, safetyData, econsultationData, resultsData } from 'utils/config/faqs';

const localConfig = [
  { id: 0, data: costsData },
  { id: 1, data: guaranteeData },
  { id: 2, data: dentistsData },
  { id: 3, data: labData },
  { id: 4, data: safetyData },
  { id: 5, data: econsultationData },
  { id: 6, data: resultsData },
];

const SectionTopics = ({ className }) => {
  const [active, toggleTopic] = useState(null);

  return (
    <div className={cx('section-topics', [className])}>
      <div id='focus-topics' className='cta-backtop-target' />
      <div className='topics-wrapper'>
        <Button label='Costs' level='outline' onClick={() => toggleTopic(0)} />
        <Button label='Guarantee' level='outline' onClick={() => toggleTopic(1)} />
        <Button label='Dentists' level='outline' onClick={() => toggleTopic(2)} />
        <Button label='Lab R&D' level='outline' onClick={() => toggleTopic(3)} />
      </div>
      <div className='topics-wrapper'>
        <Button label='Safety' level='outline' onClick={() => toggleTopic(4)} />
        <Button label='Free E-Consultation' level='outline' onClick={() => toggleTopic(5)} />
        <Button label='Results' level='outline' onClick={() => toggleTopic(6)} />
      </div>

      {localConfig.map(({ id, data }) => (
        <div key={id} className={cx('topic-wrapper', { 'hide': active !== id })}>
          {data.map(({ id, title, text }) => (
            <Fragment key={id}>
              <div className='topic-question'><div className='shape' /><p><b>{title}</b></p></div>
              <div className='topic-answer'><div className='shape' /><p>{text}</p></div>
            </Fragment>
          ))}
        </div>
      ))}

      {active !== null && <a href='#focus-topics' className='cta-backtop'>back to top</a>}
    </div>
  );
};

SectionTopics.propTypes = {
  className: PropTypes.string.isRequired,
};

SectionTopics.defaultProps = {
  className: '',
};

export default SectionTopics;
