import React from 'react';
import PropTypes from 'prop-types';

import { Image, Link } from 'components';
import { routes } from 'utils/routes';
import { footerLinks } from 'utils/config/footer';

const Footer = ({ className }) => (
  <footer className={className}>
    <div className='footer-wrapper flex-center'>
      <p className='footer-brand large'><b>Smile More. Pay Less&trade;</b></p>
      <div className='flex'>
        <Link to={routes.login.path} {...routes.login} className='button ghost mr-20'>Log in</Link>
        <Link to={routes.getStarted.path} {...routes.getStarted} className='button'>Free e-consultation</Link>
      </div>
    </div>

    <hr/>

    <div className='footer-wrapper'>
      <div className='footer-icons'>
        <div className='icon-wrapper'>
          <Image url='icons/icon_invisible.svg' alt='Eye strikeout' />
          <p>Invisible</p>
        </div>
        <div className='icon-wrapper ml-40 mr-40'>
          <Image url='icons/icon_affordable.svg' alt='Coins' />
          <p>Affordable</p>
        </div>
        <div className='icon-wrapper'>
          <Image url='icons/icon_athome.svg' alt='Box delivered at home' />
          <p>At home</p>
        </div>
      </div>
      <div className='footer-sitemap'>
        {footerLinks.map(({ title, links }) => (
          <div className='links stacked' key={title}>
            <p className='large title'><b>{title}</b></p>
            {links.map(({ label, path, external, newTab }, idx) =>
              path === '/#'
                ? <span key={idx}>{label}</span>
                : <Link key={idx} to={path} external={external} newTab={newTab}>{label}</Link>
            )}
          </div>
        ))}
      </div>
      <div className='footer-badges links stacked'>
        <p className='large title'><b>Download</b></p>
        <Link to={routes.appStore.path} {...routes.appStore}>
          <Image url='logos/badge_appstore.png' alt='App store badge' className='footer-badge' />
        </Link>
        <Link to={routes.playStore.path} {...routes.playStore}>
          <Image url='logos/badge_playstore.png' alt='Play store badge' className='footer-badge' />
        </Link>
      </div>
    </div>

    <hr/>

    <div className='footer-wrapper footer-social'>
      <Image url='logos/stripe.png' alt='Powered by stripe' className='footer-stripe' />
      <div className='flex-center'>
        <Link to={routes.instagram.path} {...routes.instagram}>
          <Image url='icons/icon_instagram.svg' alt='Instagram logo' />
        </Link>
        <Link to={routes.facebook.path} {...routes.facebook} className='ml-20 mr-20'>
          <Image url='icons/icon_facebook.svg' alt='Facebook logo' />
        </Link>
        <Link to={routes.youtube.path} {...routes.youtube}>
          <Image url='icons/icon_youtube.svg' alt='Youtube logo' />
        </Link>
      </div>
    </div>

    <div className='copyright'>
      <span>&copy; {new Date().getFullYear()} Straight Teeth Direct&trade;. All rights reserved.</span>
      <div className='links'>
        <Link to={routes.privacy.path} {...routes.privacy}>Privacy</Link>
        <Link to={routes.refunds.path} {...routes.refunds}>Returns & Refunds</Link>
        <Link to={routes.terms.path} {...routes.terms}>Terms</Link>
        <Link to={routes.cookies.path} {...routes.cookies}>Cookies</Link>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  className: PropTypes.string.isRequired,
};

Footer.defaultProps = {
  className: '',
};

export default Footer;
